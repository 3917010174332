/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.map-modal {
  // --border-radius: 10px;

  &::part(content) {
    top: 0;
    // handle size
    // top: -16px;
    height: auto;
  }
}

.header-avatar {
  width: 32px;
  height: 32px;
}

.loading-top-bar {
  position: absolute;
  width: 100%;
  z-index: 500;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.errors {
  color: var(--ion-color-danger);
  font-size: 75%;
}

.xp-toast {
  --start: auto;
}

.about-alert {
  img {
    width: 30%;
    float: left;
    margin-right: 16px;
    border-radius: 4px;
  }

  .alert-message {
    max-height: none;
  }
}

// media queries
// https://ionicframework.com/docs/layout/css-utilities#ionic-breakpoints
@mixin media($breakpoint) {
  @if sm == $breakpoint {
    @media only screen and (min-width: 576px) {
      @content;
    }
  }

  @if md == $breakpoint {
    @media only screen and (min-width: 768px) {
      @content;
    }
  }

  @if lg == $breakpoint {
    @media only screen and (min-width: 992px) {
      @content;
    }
  }

  @if xl == $breakpoint {
    @media only screen and (min-width: 1200px) {
      @content;
    }
  }
}
